export const getLocaleStorage = (name) => {
    const data = localStorage.getItem(name)
    if(!data) return {}
    return JSON.parse(data)
}

export const setLocaleStorage = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data))
}

export const appendLocaleStorage = (name, data) =>{
    const prevData = getLocaleStorage(name)
    setLocaleStorage({...prevData, ...data})
}