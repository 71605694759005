<template>
  <accept-cookies></accept-cookies>
  <TheHeader></TheHeader>
  <RouterView></RouterView>
  <TheFooter />
</template>

<script>
import TheFooter from "./components/TheFooter.vue";
import TheHeader from "./components/TheHeader.vue";
import AcceptCookies from "@/components/acceptCookies.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    AcceptCookies,
  },
};
</script>
