import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      localeLanguage: "EN",
    };
  },
  mutations: {
    setLocaleLanguage(state, payload) {
      state.localeLanguage = payload.value;
    },
  },
  actions: {
    setLocaleLanguage(context, payload) {
      context.commit("setLocaleLanguage", payload);
    },
  },
});

export default store;
