<template>
  <div
    v-if="isAccept === undefined"
    class="_container z-10 bg-white fixed bottom-0 left-0 right-0 m-auto shadow p-4 flex flex-col w-full justify-between gap-6"
  >
    <h3 class="text-2xl font-bold">{{ $t("We value your privacy") }}</h3>
    <div>
      {{ $t("cookies message") }}
    </div>
    <div class="self-end flex gap-2">
      <custom-button
        @click="decline"
        class="bg-secondary p-2 _black-text"
        :title="$t('Decline')"
      />
      <custom-button
        @click="accept"
        class="bg-primary p-2"
        :title="$t('Accept')"
      />
    </div>
  </div>
</template>
<script>
import {
  getAcceptCookies,
  setAcceptCookies,
} from "@/utils/localStorage/cookies";
import CustomButton from "@/layouts/customButton";
export default {
  components: { CustomButton },
  data() {
    return {
      isAccept: undefined,
    };
  },
  beforeMount() {
    this.isAccept = getAcceptCookies();
  },
  methods: {
    accept() {
      this.isAccept = true;
      setAcceptCookies(true);
    },
    decline() {
      this.isAccept = false;
      setAcceptCookies(false);
    },
  },
};
</script>
<style scoped>
._container {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px 5px 0 0;
}
._black-text {
  color: #000 !important;
}
</style>
