import { getLocaleStorage, setLocaleStorage } from "./localstorage"

const NAME="acceptCookies"

export const getAcceptCookies = () => {
    return getLocaleStorage(NAME)?.isAccept // undefined | boolean
}

export const setAcceptCookies = (isAccept) => {
    setLocaleStorage(NAME, {isAccept})
}