<template>
  <button
    class="flex text-center items-center justify-center rounded-[3rem] py-3 bg-primary text-white px-6"
  >
    {{ title }}
  </button>
</template>
<script>
export default {
  props: ["title"],
};
</script>
