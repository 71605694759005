<template>
  <footer>
    <div class="w-full bg-[#272727] text-[#fff] px-4 lg:px-24 xl:px-48 py-10">
      <div
        class="flex justify-between flex-col items-center sm:items-start md:flex-row gap-10"
      >
        <RouterLink to="/home">
          <figure class="w-[10rem] min-w-[10rem] h-auto">
            <img src="../assets/logo2.svg" alt="nexatek logo" title="NEXATEK" />
          </figure>
        </RouterLink>
        <div
          class="flex flex-col sm:flex-row gap-8 sm:gap-16 justify-between sm:w-full items-start"
        >
          <div class="flex flex-col *:cursor-pointer">
            <RouterLink
              class="font-bold mb-4 hover:underline decoration-primary underline-offset-2"
              to="/products"
              >{{ $t("Products") }}</RouterLink
            >
            <a
              @click="reRouter('/products', '#nexaChat')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("NexaChat") }}</a
            >
            <a
              @click="reRouter('/products', '#nexaReport')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("NexaReport") }}</a
            >
            <a
              @click="reRouter('/products', '#nexaDAM')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("NexaDAM") }}</a
            >
            <a
              @click="reRouter('/products', '#nexaPresent')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("NexaPresent") }}</a
            >
          </div>
          <div class="flex flex-col *:cursor-pointer">
            <RouterLink
              class="font-bold mb-4 hover:underline decoration-primary underline-offset-2"
              to="/services"
              >{{ $t("Services") }}</RouterLink
            >

            <a
              @click="reRouter('/services', '#agileSoftware')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("Agile Software Development") }}</a
            >
            <a
              @click="() => reRouter('/services', '#digitalization')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("Digitalization in Civil Engineering") }}</a
            >
            <a
              @click="reRouter('/services', '#processDigitalization')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("Process Digitalization") }}</a
            >
            <a
              @click="reRouter('/services', '#customerPortal')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("Customer Portal Development") }}</a
            >
            <a
              @click="reRouter('/services', '#RDDevelopment')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("R&D in Civil Engineering") }}</a
            >
            <a
              @click="reRouter('/services', '#appDevelopment')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("App Development") }}</a
            >
            <a
              @click="reRouter('/services', '#digitalDatabase')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("Digital Database Producer") }}</a
            >
          </div>
          <div class="flex flex-col *:cursor-pointer">
            <RouterLink
              class="font-bold mb-4 hover:underline decoration-primary underline-offset-2"
              to="/company"
              >{{ $t("Company") }}</RouterLink
            >

            <a
              @click="this.$router.push('/company')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("About us") }}</a
            >
            <a
              @click="reRouter('/company', '#career')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("Career") }}</a
            >
            <a
              @click="reRouter('/company', '#partnership')"
              class="hover:underline decoration-primary underline-offset-2"
              >{{ $t("Become our partner") }}</a
            >
            <a
              @click="reRouter('/company', '#contactus')"
              class="hover:underline decoration-primary underline-offset-2"
            >
              {{ $t("Contact us") }}</a
            >
          </div>
        </div>
      </div>
      <div
        class="flex justify-between border-t-2 border-[#fff] w-full mt-4 h-full items-center flex-col sm:flex-row gap-8"
      >
        <div class="h-full">
          <div class="flex gap-2 p-4 h-full items-center justify-center">
            <button
              :class="{
                'font-bold': this.$store.state.localeLanguage === 'EN',
              }"
              @click="languageButtonClickHandler('EN')"
            >
              EN</button
            ><button
              :class="{
                'font-bold': this.$store.state.localeLanguage === 'DE',
              }"
              @click="languageButtonClickHandler('DE')"
            >
              DE
            </button>
          </div>
        </div>

        <div
          class="h-full flex justify-center items-center sm:justify-end px-4 py-2 gap-2 sm:gap-8"
        >
          <a
            class="cursor-pointer"
            target="_blank"
            href="https://www.facebook.com/nexatekgroup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path
                d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"
              ></path>
            </svg>
          </a>
          <a
            class="cursor-pointer"
            target="_blank"
            href="https://twitter.com/NEXATEKGroup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path
                d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"
              ></path>
            </svg>
          </a>
          <a
            class="cursor-pointer"
            target="_blank"
            href="https://www.instagram.com/nexatek.group/"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path
                d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
              ></path>
            </svg>
          </a>
          <a
            class="cursor-pointer"
            target="_blank"
            href="https://www.youtube.com/@NEXATEKGroup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="60"
              height="60"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path
                d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"
              ></path>
            </svg>
          </a>
          <a
            class="cursor-pointer"
            target="_blank"
            href="https://www.linkedin.com/company/nexatekgroup"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="white"
            >
              <path
                d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    reRouter(path, hash) {
      this.$router.push({
        path: path,
        hash: hash,
      });
    },
    languageButtonClickHandler(lang) {
      this.$store.dispatch({ type: "setLocaleLanguage", value: lang });
      this.$i18n.locale = lang;
    },
  },
};
</script>
