<template>
  <header>
    <div :class="{ 'fixed z-20': backdropIsvisible }" class="w-full">
      <div
        :class="{ 'xl:px-24': this.$store.state.localeLanguage === 'DE' }"
        class="bg-secondary px-2 lg:px-24 xl:px-48 flex flex-col w-full justify-between items-center py-3"
      >
        <div
          class="flex justify-between items-center w-full flex-col md:flex-row"
        >
          <div class="flex justify-between items-center w-full md:w-auto">
            <RouterLink to="/home" @click="navbarReset">
              <figure class="min-w-[10rem] w-[10rem] h-auto">
                <img
                  src="../assets/logo.svg"
                  class="w-full"
                  alt="nexatek logo"
                  title="NEXATEK"
                />
              </figure>
            </RouterLink>
            <div>
              <button
                @click="hamburgerClickHandler"
                class="md:hidden h-full min-h-20 flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="50"
                  height="50"
                  viewBox="0 0 72 72"
                >
                  <path
                    d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div
            :class="{
              'max-h-0': !hamburgerIsClicked,
              'max-h-[1000px]': hamburgerIsClicked,
            }"
            class="flex w-full md:w-auto transition-height duration-1000 overflow-hidden md:h-full md:overflow-visible"
          >
            <nav class="py-1 w-full md:w-auto">
              <ul
                class="flex md:items-center text-xl font-medium flex-col md:flex-row h-full w-full md:w-auto"
                :class="{
                  'gap-[0px] xl:gap-4': isDe,
                  'gap-4': !isDe,
                }"
              >
                <li
                  class="relative flex items-center justify-start md:hidden xl:flex"
                >
                  <RouterLink
                    :class="{
                      'text-[16px] lg:text-xl': isDe,
                      'text-xl': !isDe,
                    }"
                    @touchend.prevent="homeTouchHandler"
                    class="w-full hover:bg-primary hover:bg-opacity-50 hover:text-white transition duration-1000 p-2"
                    to="/home"
                    >{{ $t("Home") }}</RouterLink
                  >
                </li>
                <li
                  class="relative group flex flex-col items-center justify-start w-full"
                >
                  <RouterLink
                    :class="{
                      'bg-primary text-white': productIsTouched,
                      'bg-none text-black': !productIsTouched,
                      'text-[16px] lg:text-xl': isDe,
                      'text-xl': !isDe,
                    }"
                    @mouseover="productMouseOver"
                    @touchend.prevent="productTouchHandler"
                    class="hover:bg-primary hover:bg-opacity-50 hover:text-white transition duration-1000 group-hover:bg-primary group-hover:text-white w-full p-2"
                    to="/products"
                    >{{ $t("Products") }}</RouterLink
                  >
                  <div
                    class="absolute top-full w-full h-[1rem] bg-transparent hidden md:flex"
                  ></div>

                  <ul
                    ref="productsList"
                    :class="{
                      'opacity-100 z-30 visible max-h-[300px] ':
                        productIsTouched,
                      'opacity-0 invisible max-h-0': !productIsTouched,
                    }"
                    class="p-2 md:p-5 max-h-0 group-hover:max-h-[300px] overflow-hidden w-full md:w-auto transition duration-1000 bg-[#e9e9e994] md:backdrop-blur-lg group-hover:opacity-100 group-hover:visible md:absolute md:top-[3.75rem] md:left-0 md:rounded-bl-[1.25rem] md:rounded-br-[1.25rem]"
                  >
                    <p
                      class="border-b-2 border-primary mb-4 font-semibold pb-4 hidden md:block"
                    >
                      {{ $t("Products") }}
                    </p>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/products', '#nexaChat')"
                    >
                      <a>
                        {{ $t("NexaChat") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/products', '#nexaReport')"
                    >
                      <a>
                        {{ $t("NexaReport") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/products', '#nexaDAM')"
                    >
                      <a>
                        {{ $t("NexaDAM") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/products', '#nexaPresent')"
                    >
                      <a>
                        {{ $t("NexaPresent") }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  class="relative group flex flex-col items-center justify-start w-full"
                >
                  <RouterLink
                    :class="{
                      'bg-primary text-white': servicesIsTouched,
                      'bg-none text-black': !servicesIsTouched,
                      'text-[16px] lg:text-xl ': isDe,
                      'text-xl': !isDe,
                    }"
                    @mouseover="servicesMouseOver"
                    @touchend.prevent="servicesTouchHandler"
                    class="hover:bg-primary hover:bg-opacity-50 hover:text-white transition duration-1000 group-hover:bg-primary group-hover:text-white w-full p-2"
                    to="/services"
                  >
                    {{ $t("Services") }}
                  </RouterLink>
                  <div
                    class="absolute top-full w-full h-[1rem] bg-transparent hidden md:flex"
                  ></div>
                  <ul
                    :class="{
                      'opacity-100 z-30 visible max-h-[500px] ':
                        servicesIsTouched,
                      'opacity-0 invisible max-h-0': !servicesIsTouched,
                    }"
                    class="p-2 md:p-5 max-h-0 group-hover:max-h-[500px] overflow-hidden w-full md:w-auto transition duration-1000 bg-[#e9e9e994] md:backdrop-blur-lg group-hover:opacity-100 group-hover:visible md:absolute md:top-[3.75rem] md:left-0 md:rounded-bl-[1.25rem] md:rounded-br-[1.25rem]"
                  >
                    <p
                      class="border-b-2 border-primary mb-4 font-semibold pb-4 hidden md:block"
                    >
                      {{ $t("Services") }}
                    </p>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#agileSoftware')"
                    >
                      <a>
                        {{ $t("Agile Software Development") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="() => reRouter('/services', '#digitalization')"
                    >
                      <a>
                        {{ $t("Digitalization in Civil Engineering") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#processDigitalization')"
                    >
                      <a>
                        {{ $t("Process Digitalization") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#customerPortal')"
                    >
                      <a>
                        {{ $t("Customer Portal Development") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#RDDevelopment')"
                    >
                      <a>
                        {{ $t("R&D in Civil Engineering") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#appDevelopment')"
                    >
                      <a>
                        {{ $t("App Development") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/services', '#digitalDatabase')"
                    >
                      <a>
                        {{ $t("Digital Database Producer") }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  class="relative group flex flex-col items-center justify-start w-full"
                >
                  <RouterLink
                    :class="{
                      'bg-primary text-white': companyIstouched,
                      'bg-none text-black': !companyIstouched,
                      'text-[16px] lg:text-xl': isDe,
                      'text-xl': !isDe,
                    }"
                    @mouseover="companyMouseOver"
                    @touchend.prevent="companyTouchhandler"
                    class="hover:bg-primary hover:bg-opacity-50 hover:text-white transition duration-1000 group-hover:bg-primary group-hover:text-white w-full p-2"
                    to="/company"
                  >
                    {{ $t("Company") }}
                  </RouterLink>
                  <div
                    class="absolute top-full w-full h-[1rem] bg-transparent hidden md:flex"
                  ></div>
                  <ul
                    :class="{
                      'opacity-100 z-30 visible max-h-[300px] ':
                        companyIstouched,
                      'opacity-0 invisible max-h-0': !companyIstouched,
                    }"
                    class="p-2 md:p-5 max-h-0 group-hover:max-h-[300px] overflow-hidden w-full md:w-auto transition duration-1000 bg-[#e9e9e994] md:backdrop-blur-lg group-hover:opacity-100 group-hover:visible md:absolute md:top-[3.75rem] md:left-0 md:rounded-bl-[1.25rem] md:rounded-br-[1.25rem]"
                  >
                    <p
                      class="w-full border-b-2 border-primary mb-4 font-semibold pb-4 hidden md:block"
                    >
                      {{ $t("Company") }}
                    </p>

                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="() => reRouter('/company', '#')"
                    >
                      <a>
                        {{ $t("About us") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/company', '#career')"
                    >
                      <a> {{ $t("Career") }} </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/company', '#partnership')"
                    >
                      <a>
                        {{ $t("Become our partner") }}
                      </a>
                    </li>
                    <li
                      class="py-2 text-base w-full text-nowrap min-w-[16rem] leading-7 hover:text-primary cursor-pointer"
                      @click="reRouter('/company', '#contactus')"
                    >
                      <a>
                        {{ $t("Contact us") }}
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="flex items-center justify-start">
                  <div
                    class="flex text-sm gap-1 md:w-[8rem] items-center justify-center p-2 md:hidden"
                  >
                    <button
                      :class="{
                        'text-primary font-bold':
                          this.$store.state.localeLanguage === 'EN',
                      }"
                      @click="languageButtonClickHandler('EN')"
                    >
                      EN</button
                    ><button
                      :class="{
                        'text-primary font-bold':
                          this.$store.state.localeLanguage === 'DE',
                      }"
                      @click="languageButtonClickHandler('DE')"
                    >
                      DE
                    </button>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div class="hidden md:flex justify-center items-center gap-2">
            <div @click="reRouter('/company', '#contactus')">
              <customButton :title="$t('Contact us')" class="p-0 text-nowrap" />
            </div>
            <div
              class="text-sm gap-1 items-center justify-center hidden md:flex"
            >
              <button
                :class="{
                  'text-primary font-bold':
                    this.$store.state.localeLanguage === 'EN',
                }"
                @click="languageButtonClickHandler('EN')"
              >
                EN</button
              ><button
                :class="{
                  'text-primary font-bold':
                    this.$store.state.localeLanguage === 'DE',
                }"
                @click="languageButtonClickHandler('DE')"
              >
                DE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        @click="backdropTouchHandler"
        @touchend="backdropTouchHandler"
        v-if="backdropIsvisible"
        class="h-full w-full min-h-screen bg-black bg-opacity-50 backdrop-blur z-20"
      ></div>
    </div>
  </header>
</template>
<script>
import customButton from "@/layouts/customButton.vue";
export default {
  data() {
    return {
      hamburgerIsClicked: false,
      isMobile: false,
      productIsTouched: false,
      servicesIsTouched: false,
      companyIstouched: false,
      backdropIsvisible: false,
      languageIsDe: false,
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    if (window.innerWidth >= 768) {
      this.hamburgerIsClicked = true;
    }
    window.addEventListener("resize", this.checkMobile);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth < 768;
      this.hamburgerIsClicked = window.innerWidth >= 768;
    },

    reRouter(path, hash) {
      this.$router.push({
        path: path,
        hash: hash,
      });
      this.hamburgerIsClicked = false;
      this.navbarReset();
    },
    hamburgerClickHandler() {
      this.hamburgerIsClicked = !this.hamburgerIsClicked;
      this.backdropIsvisible = this.hamburgerIsClicked;
      if (!this.hamburgerIsClicked) {
        this.navbarReset();
      }
    },
    homeTouchHandler() {
      this.$router.push("/home");
      this.hamburgerIsClicked = false;
      this.navbarReset();
    },
    productTouchHandler() {
      if (!this.productIsTouched) {
        this.productIsTouched = true;
        this.backdropIsvisible = true;
      } else {
        this.backdropIsvisible = false;
        this.productIsTouched = false;
        this.$router.push("/products");
        this.hamburgerIsClicked = false;
      }

      this.servicesIsTouched = false;
      this.companyIstouched = false;
    },
    servicesTouchHandler() {
      if (!this.servicesIsTouched) {
        this.servicesIsTouched = true;
        this.backdropIsvisible = true;
      } else {
        this.backdropIsvisible = false;
        this.servicesIsTouched = false;
        this.$router.push("/services");
        this.hamburgerIsClicked = false;
      }

      this.productIsTouched = false;
      this.companyIstouched = false;
    },
    companyTouchhandler() {
      if (!this.companyIstouched) {
        this.companyIstouched = true;
        this.backdropIsvisible = true;
      } else {
        this.backdropIsvisible = false;
        this.companyIstouched = false;
        this.$router.push("/company");
        this.hamburgerIsClicked = false;
      }
      this.productIsTouched = false;
      this.servicesIsTouched = false;
    },
    backdropTouchHandler() {
      this.productIsTouched = false;
      this.servicesIsTouched = false;
      this.companyIstouched = false;
      this.backdropIsvisible = false;
      this.hamburgerIsClicked = false;
    },
    productMouseOver() {
      this.servicesIsTouched = false;
      this.companyIstouched = false;
    },
    servicesMouseOver() {
      this.productIsTouched = false;
      this.companyIstouched = false;
    },
    companyMouseOver() {
      this.productIsTouched = false;
      this.servicesIsTouched = false;
    },
    navbarReset() {
      this.productIsTouched = false;
      this.companyIstouched = false;
      this.servicesIsTouched = false;

      this.backdropIsvisible = false;
    },
    languageButtonClickHandler(lang) {
      this.$store.dispatch({ type: "setLocaleLanguage", value: lang });
      this.$i18n.locale = lang;
    },
  },
  computed: {
    isDe() {
      return this.$store.state.localeLanguage === "DE";
    },
  },

  components: {
    customButton,
  },
};
</script>
<style scoped>
ul > li > .router-link-active {
  border-bottom: 2px solid #1fab91;
}
</style>
